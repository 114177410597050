<template>
  <div>
    <topDiv style="width: 99.1vw;height: 3vh"></topDiv>

    <div class="container">
      <div class="left-div">
        <el-row class="tac">
          <el-col :span="12">
            <el-menu
              default-active="2"
              class="el-menu-vertical-demo"
              @open="handleOpen"
              @close="handleClose"
              style="width: 200px;">
              <el-menu-item index="1" @click="changeNum(1)">
                <i class="el-icon-user-solid"></i>
                <span slot="title" >个人信息</span>
              </el-menu-item>
              <el-submenu index="2" default-active="2-0" >
                <template slot="title">
                  <i class="el-icon-stopwatch" @click="changeNum(2)"></i>
                  <span @click="changeNum(2)">我的船队</span>
                </template>
                <el-menu-item-group>
                  <!--                  <el-menu-item index="2-0" @click="changeNum(2)">首页</el-menu-item>-->
                  <el-menu-item
                    v-for="(fleet, index) in shipList"
                    :key="index"
                    :index="`2-${index + 1}`"
                    @click="selectFleet(index)">
                    {{ fleet.name }}
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>


              <el-submenu index="3">
                <template slot="title">
                  <i class="el-icon-stopwatch" @click="changeNum(3)"></i>
                  <span @click="changeNum(3)">我的区域</span>
                </template>
                <el-menu-item-group>
                  <!--                  <el-menu-item index="3-0"  @click="changeNum(3)">首页</el-menu-item>-->
                  <el-menu-item index="3-1"  @click="changeNum(31)">区域1</el-menu-item>
                  <el-menu-item index="3-2"  @click="changeNum(31)">区域2</el-menu-item>
                  <el-menu-item index="3-3"  @click="changeNum(31)">区域3</el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <el-menu-item index="4" @click="changeNum(4)">
                <i class="el-icon-chat-dot-round"></i>
                <span slot="title" >消息</span>
              </el-menu-item>
            </el-menu>
          </el-col>
        </el-row>
      </div>



      <div class="right-div">
        <div v-if="this.num === 1">
          <p style="color: #888888;margin-left: 25px">当前位置：个人中心--个人信息</p>
          <div style="width: 97%; height: 170px; border-radius: 7px; background: #ffffff; margin-left: 25px;">
            <div class="flex-container">
              <div class="block"><el-avatar :size="50" :src="circleUrl" style="margin: 20px"></el-avatar></div>
              <h2 style="color: #888888">{{ this.userName }}</h2>
            </div>
            <div class="flex-container">
              <p style="color: #888888;margin-left: 25px">用户名: </p> <b style="color: #000000;margin-left: 15px">{{ this.userName }}</b>
              <p style="color: #888888;margin-left: 80px">密码 : </p>
              <el-input
                placeholder="******"
                v-model="password"
                :disabled="true"
                style="width: 250px;margin-left: 30px;margin-right: 50px"></el-input>
              <el-button type="info" icon="el-icon-lock" plain>修改密码</el-button>
              <el-button type="info" icon="el-icon-key" plain>忘记密码</el-button>


            </div>
          </div>



          <div style="width: 97%;height: 630px;border-radius: 7px;background: #ffffff; margin-left: 25px; margin-top:30px ">
            <h2 style="color: #888888; margin-left:20px;padding-top: 10px">账户信息</h2>
            <hr style="padding-top: -10px">
            <div>
              <div class="flex-container">
                <div>
                  <p style="color: #888888; margin-right: 10px; margin-left: 20px">姓名：</p>
                  <el-input
                    placeholder="请输入姓名"
                    v-model="allInfo.name"
                    :disabled="changeInfo === false"
                    style="width: 450px;margin-left: 20px"></el-input>
                </div>
                <div>
                  <p style="color: #888888; margin-right: 10px;margin-left: 40px">手机：</p>
                  <el-input
                    placeholder="请输入手机"
                    v-model="allInfo.telephone"
                    :disabled="changeInfo === false"
                    style="width: 450px;margin-left: 40px"></el-input>
                </div>
                <div>
                  <p style="color: #888888; margin-right: 10px;margin-left: 40px">邮箱：</p>
                  <el-input
                    placeholder="请输入邮箱"
                    v-model="allInfo.email"
                    :disabled="changeInfo === false"
                    style="width: 450px;margin-left: 40px"></el-input>
                </div>

              </div>
              <h2 style="color: #888888; margin-left:20px;padding-top: 10px">企业信息</h2>


              <hr style="padding-top: -10px">
              <div class="flex-container">
                <div>
                  <p style="color: #888888; margin-right: 10px;margin-left: 20px">公司名称：</p>
                  <el-input
                    placeholder="请输入公司名称"
                    v-model="allInfo.companyName"
                    :disabled="changeInfo === false"
                    style="width: 450px;margin-left: 20px"></el-input>
                </div>
                <div>
                  <p style="color: #888888; margin-right: 10px;margin-left: 40px">公司邮箱：</p>
                  <el-input
                    placeholder="请输入公司邮箱"
                    v-model="allInfo.companyEmail"
                    :disabled="changeInfo === false"
                    style="width: 450px;margin-left: 40px"></el-input>
                </div>
                <div>
                  <p style="color: #888888; margin-right: 10px;margin-left: 40px">公司电话：</p>
                  <el-input
                    placeholder="请输入公司电话"
                    v-model="allInfo.companyPhone"
                    :disabled="changeInfo === false"
                    style="width: 450px;margin-left: 40px"></el-input>
                </div>

              </div>
              <div class="flex-container">
                <div>
                  <p style="color: #888888; margin-right: 10px;margin-left: 20px">公司网址：</p>
                  <el-input
                    placeholder="请输入公司网址"
                    v-model="allInfo.companyNet"
                    :disabled="changeInfo === false"
                    style="width: 450px;margin-left: 20px"></el-input>
                </div>
                <div>
                  <p style="color: #888888; margin-right: 10px;margin-left: 40px">邮编：</p>
                  <el-input
                    placeholder="请输入公司邮编"
                    v-model="allInfo.companyYoubian"
                    :disabled="changeInfo === false"
                    style="width: 450px;margin-left: 40px"></el-input>
                </div>
                <div>
                  <p style="color: #888888; margin-right: 10px;margin-left: 40px">国家/地区：</p>
                  <el-input
                    placeholder="请输入国家/地区"
                    v-model="allInfo.companyCountry"
                    :disabled="changeInfo === false"
                    style="width: 450px;margin-left: 40px"></el-input>
                </div>

              </div>
              <div class="flex-container">
                <div>
                  <p style="color: #888888; margin-right: 10px;margin-left: 20px">省份：</p>
                  <el-input
                    placeholder="请输入省份"
                    v-model="allInfo.companyProvince"
                    :disabled="changeInfo === false"
                    style="width: 450px;margin-left: 20px"></el-input>
                </div>
                <div>
                  <p style="color: #888888; margin-right: 10px;margin-left: 40px">城市：</p>
                  <el-input
                    placeholder="请输入城市"
                    v-model="allInfo.companyCity"
                    :disabled="changeInfo === false"
                    style="width: 450px;margin-left: 40px"></el-input>
                </div>
                <div>
                  <p style="color: #888888; margin-right: 10px;margin-left: 40px">&nbsp;<br></p>
                  <el-button type="primary" style="margin-left: 40px" @click="changeMyInfo(true)" plain>修改</el-button>
                  <el-button type="success" style="margin-left: 20px" @click="changeMyInfo(false)" plain>保存</el-button>
                </div>

              </div>
              <div class="flex-container">
                <div>
                  <p style="color: #888888; margin-right: 10px;margin-left: 20px">详细地址：</p>
                  <el-input
                    placeholder="请输入详细地址"
                    v-model="allInfo.companyPosition"
                    :disabled="changeInfo === false"
                    style="width: 940px;margin-left: 20px"></el-input>
                </div>

              </div>

            </div>

          </div>


        </div>

        <div v-if="this.num === 2">
          <p style="color: #888888;margin-left: 20px">当前位置：个人中心--我的船队</p>
          <div style="display: flex;">
            <div style="width: 30%; height: 800px; border-radius: 7px; background: #ffffff; margin-left: 25px; overflow-y: auto;">
              <div style="display: flex; align-items: center; color: #888888; margin: 30px 25px 30px 30px;">
                <h2 style="flex: 1; margin: 0;">我的船队</h2>
                <el-button @click.stop="showNewFleetDialog">新建船队</el-button>
              </div>

              <div v-for="(fleet, fleetKey) in shipList" class="shipListClass" :key="fleetKey" style="margin-bottom: 20px;" >
                <div style="background-color: #f4f4f5; padding: 5px; border-radius: 5px; display: flex; align-items: center; justify-content: space-between;">
                  <h2 class="fleet-name" style="margin-right: auto; cursor: pointer;" @click="selectFleet(fleetKey)">🛶️{{ fleet.name }}</h2>

                  <el-button @click.stop="showEditDialog(fleetKey)">修改名称</el-button> <!-- 使用 .stop 阻止事件冒泡 -->
                  <el-popconfirm title="确定删除整只船队吗？" @confirm="deleteFleet(fleetKey)">
                    <el-button slot="reference">删除船队</el-button>
                  </el-popconfirm>
                </div>

                <ul style="list-style-type: none;">
                  <li v-for="(ship, shipIndex) in fleet.ships" :key="shipIndex" style="background-color: #ecf5ff; padding: 5px; border-radius: 5px; margin-top: 5px; display: flex; justify-content: space-between;">
                    ⛵{{ ship.name }}
                    <el-popconfirm title="确定移出该船吗？" @confirm="removeShip(fleetKey, shipIndex)">
                      <el-button slot="reference">移出船队</el-button>
                    </el-popconfirm>
                  </li>
                  <!-- 添加船舶的部分保持不变 -->
                  <li class="hover-li" style="height: 27px; width:97.5%; padding: 5px; border-radius: 5px; margin-top: 5px; display: flex; justify-content: space-between;" @click="addNewBoat(fleetKey)">
                    ＋添加船舶
                  </li>
                </ul>
              </div>


              <!-- 修改船队名称的对话框 -->
              <el-dialog :visible.sync="editDialogVisible" title="修改船队名称">
                <el-input v-model="tempFleetName" placeholder="请输入新的船队名称"></el-input>
                <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="updateFleetName">确认</el-button>
      </span>
              </el-dialog>

              <el-dialog :visible.sync="addNewFleet" title="新建船队名称">
                <el-input v-model="newFleetName" placeholder="请输入船队名称"></el-input>
                <span slot="footer" class="dialog-footer">
        <el-button @click="addNewFleet = false;">取消</el-button>
        <el-button :plain="true" type="primary" @click="addFleet(newFleetName);">确认</el-button>
      </span>
              </el-dialog>



            </div>

            <div style="width: 65%; height: 800px; border-radius: 7px; background: #ffffff; margin-left: 25px;">
              <div style="display: flex; align-items: center; color: #888888; margin: 30px 25px 30px 30px;">
                <h2 style="flex: 1; margin: 0;">船队管理：{{this.currentSelectFleet.name}}</h2>
              </div>

              <div v-if="this.page === 1" class="container2">
                <div class="left-side">

                  <div class="my-box" style="height:75%;margin-top: 40px" > <!-- 调整整体间距 -->
                    <h2 style="margin-bottom: 20px;">船舶信息</h2>
                    <!-- 添加左右布局的容器 -->
                    <div style="display: flex; width: 100%; height: 48vh;">
                      <!-- 左侧区域 -->
                      <div style="flex: 31.4%;overflow-y: auto;">
                        <div style="background-color: #f4f4f5; padding: 3px; border-radius: 5px;">
                          <div v-for="(ship, shipKey) in currentSelectFleet.ships" :key="shipKey" :class="{'boatInfoFrame':true, 'selectedFleetBoatColor':selectedBoat === ship }" @click="selectBoat(ship);">
                            <p style="margin-bottom: -8px;margin-top: -2px">{{ shipKey + 1 }}.<strong>{{ ship.name }}</strong></p>
                            <p style="font-size: 12px;margin-bottom: -8px">MMSI: {{ ship.mmsi }} &nbsp;&nbsp; </p>
                            <p style="font-size: 12px">IMO: {{ ship.imo }}</p>
                          </div>
                        </div>



                        <!-- 左侧内容 -->
                      </div>
                      <!-- 右侧区域 -->
                      <div style="flex: 68.6%;  border: 1px solid #ccc; border-radius: 5px;padding:15px 0 0 0;background-color:#ecf5ff ">
                        <!-- 右侧内容 -->
                        <div class="flex-container">
                          <div class="leftHalf">
                            <p class="item-row">
                              <span class="item-label">IMO:</span>
                              <span class="item-value">{{ selectedBoat.imo }}</span>
                            </p>
                            <p class="item-row">
                              <span class="item-label">船旗:</span>
                              <span class="item-value">{{ translateFlag(selectedBoatInfo.flag) }}</span>
                            </p>
                            <p class="item-row">
                              <span class="item-label">长/宽:</span>
                              <span class="item-value" v-if="isLog">{{ selectedBoatInfo.length }}m/{{selectedBoatInfo.width}}m</span>
                              <span class="item-value" v-if="!isLog">🔒</span>
                            </p>
                            <p class="item-row">
                              <span class="item-label">航速:</span>
                              <span class="item-value" v-if="isLog">{{ selectedBoatInfo.sog }}</span>
                              <span class="item-value" v-if="!isLog">🔒</span>
                            </p>
                            <p class="item-row">
                              <span class="item-label">预计到达时间:</span>
                              <span class="item-value" v-if="isLog">{{ (selectedBoatInfo.eta / 3600).toFixed(2) }}h</span>
                              <span class="item-value" v-if="!isLog">🔒</span>

                            </p>
                            <p class="item-row">
                              <span class="item-label">目的港口:</span>
                              <span class="item-value" v-if="isLog">{{ selectedBoatInfo.dest }}</span>
                              <span class="item-value" v-if="!isLog">🔒</span>
                            </p>
                            <p class="item-row">
                              <span class="item-label">经度:</span>
                              <span class="item-value" v-if="isLog">{{ selectedBoatInfo.x }}°</span>
                              <span class="item-value" v-if="!isLog">🔒</span>
                            </p>
                            <p class="item-row">
                              <span class="item-label">总吨位: NULL</span>
                              <span class="item-value" v-if="isLog"></span>
                              <span class="item-value" v-if="!isLog">🔒</span>
                            </p>
                            <p class="item-row">
                              <span class="item-label">载重吨: NULL</span>
                              <span class="item-value" v-if="isLog"></span>
                              <span class="item-value" v-if="!isLog">🔒</span>
                            </p>
                          </div>
                          <div class="rightHalf">
                            <p class="item-row">
                              <span class="item-label">MMSI:</span>
                              <span class="item-value">{{ selectedBoat.mmsi }}</span>
                            </p>
                            <p class="item-row">
                              <span class="item-label">类型:</span>
                              <span class="item-value" v-if="isLog">{{ translateType(selectedBoatInfo.ship_type) }}</span>
                              <span class="item-value" v-if="!isLog">🔒</span>
                            </p>
                            <p class="item-row">
                              <span class="item-label">吃水深度:</span>
                              <span class="item-value" v-if="isLog">{{selectedBoatInfo.draught }}</span>
                              <span class="item-value" v-if="!isLog">🔒</span>
                            </p>
                            <p class="item-row">
                              <span class="item-label">呼号:</span>
                              <span class="item-value" v-if="isLog">{{ selectedBoatInfo.callsign }}</span>
                              <span class="item-value" v-if="!isLog">🔒</span>
                            </p>
                            <p class="item-row">
                              <span class="item-label">母港:</span>
                              <span class="item-value" v-if="isLog">{{ selectedBoatInfo.homeport }}</span>
                              <span class="item-value" v-if="!isLog">🔒</span>
                            </p>
                            <p class="item-row">
                              <span class="item-label">状态:</span>
                              <span class="item-value" v-if="isLog">{{ translateStatus(selectedBoatInfo.nav_status) }}</span>
                              <span class="item-value" v-if="!isLog">🔒</span>
                            </p>
                            <p class="item-row">
                              <span class="item-label">纬度:</span>
                              <span class="item-value" v-if="isLog">{{ selectedBoatInfo.y }}°</span>
                              <span class="item-value" v-if="!isLog">🔒</span>
                            </p>
                            <p class="item-row">
                              <span class="item-label">净吨位: NULL</span>
                              <span class="item-value" v-if="isLog"></span>
                              <span class="item-value" v-if="!isLog">🔒</span>
                            </p>
                            <p class="item-row">
                              <span class="item-label">船籍港: NULL</span>
                              <span class="item-value" v-if="isLog"></span>
                              <span class="item-value" v-if="!isLog">🔒</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
<!--                  <img src="@/assets/img/logo3.png" alt="Logo" class="logo">-->
                </div>

                <div class="right-side"><!--                  <img src="@/assets/img/logo3.png" alt="Logo" class="logo">-->
                  <div class="my-box" style="padding-top: 5px; padding-bottom: 5px;"> <!-- 调整整体间距 -->
                    <h2 style="margin-top: 0px; margin-bottom: 0px;">示例船舶{{selectedBoat.name}}:</h2>
                    <div class="image-slider">
                      <el-carousel :interval="3000" trigger="hover" arrow="always">
                        <el-carousel-item v-for="image in this.images" :key="image">
                          <img :src="image" alt="船舶图片" style="width: 100%; height: 100%; object-fit: cover;">
                        </el-carousel-item>
                      </el-carousel>
                    </div>
                  </div>

                  <div class="my-box"> <!-- 调整整体间距 -->
                    <h2 style=" margin-bottom: 20px;">船队提醒设置</h2>
                    <div style="margin-bottom: 20px;"> <!-- 每个开关的容器，确保它们各占一行 -->
                      <span style="margin-right: 55px;">船舶ais信息变更提醒</span> <!-- 调整文字与开关的间距 -->
                      <el-switch
                        v-model="currentSelectFleet.aisChange"
                        active-text="开启"
                        inactive-text="关闭">
                      </el-switch>
                    </div>
                    <div style="margin-bottom: 20px;">
                      <span style="margin-right: 78px;">船舶出入港口提醒</span>
                      <el-switch
                        v-model="currentSelectFleet.inOut"
                        active-text="开启"
                        inactive-text="关闭">
                      </el-switch>
                    </div>
                    <div style="margin-bottom: 20px;">
                      <span style="margin-right: 78px;">船舶出入区域提醒</span>
                      <el-switch
                        v-model="currentSelectFleet.area"
                        active-text="开启"
                        inactive-text="关闭">
                      </el-switch>
                    </div>
                    <div style="margin-bottom: 20px;">
                      <span style="margin-right: 78px;">恶劣天气状况提醒</span>
                      <el-switch
                        v-model="currentSelectFleet.weather"
                        active-text="开启"
                        inactive-text="关闭">
                      </el-switch>
                    </div>
                  </div>

                </div>
              </div>

              <div v-if="this.page === 2" class="container2">
                <div class="left-div2">

                <div>
                <div class="search-box">
                  <div class="icon-container">
                    <i v-if="!searchQuery" class="fas fa-search"></i>
                    <i v-if="searchQuery" @click="clearContent" class="fas fa-times"></i>
                  </div>
                  <input
                    v-model="searchQuery"
                    type="text"
                    placeholder="请输入船舶名称/mmsi/imo"
                    class="search-input"
                    @keyup.enter="searchBoat"
                  />
                  <el-button @click="searchBoat" style="width: 84px;height: 40px;margin-left: 10px;margin-right: 30px; font-size: 14px; background-color: #f8f8f8;">搜索</el-button>
                </div>
                </div>


                  <div class="sidebar">
<!--                    <button class="close-btn" @click="closeSidebar">✖</button>-->
                    <div style=" ">
                    <p v-if="searchQuery" style="text-align: center;color: #888888;font-size: 15px;">🔍搜索结果：{{tempQuery}}
                        <span class="extra-spacing"></span>共找到
                      <span  style=" font-size: 16px ;font-weight: bolder ;color: rgb(18, 212, 18);">{{searchResults.shipInfoList.length}}
                      </span> 条结果</p>


                      <p v-if="!searchQuery" style="text-align: center;color: #888888;font-size: 15px;">🔍等待搜索</p>

                      <hr style="width: 100%; border: 2px solid #f2f2f7;">
                    </div>
                    <div class="vf-div">
                      <!-- 遍历 shipInfoList -->
                      <div v-for="(ship, index) in searchResults.shipInfoList"
                           :key="ship.id"
                           :class="{ 'search-result': true, 'selected': selectedShip === ship }"
                           @click="updateSearchQuery(ship)">
                        <p>{{ index + 1 }}. 船舶<strong>: <span style="margin-right: 10px;"></span> {{ ship.name }}</strong>
                          <span class="extra-spacing"></span>{{translateFlag(ship.flag)}}<span class="extra-spacing"></span>{{ translateType(ship.type) }}</p>
                        <p>MMSI: {{ ship.mmsi }}<span style="margin-right: 40px;"></span>IMO: {{ ship.imo }}<span class="extra-spacing"></span>
                          <el-popconfirm title="船舶将会加入到当前船队" @confirm="addBoat(ship)">
                            <el-button :plain="true" slot="reference">加入船队</el-button>
                          </el-popconfirm></p>
                      </div>
                    </div>
                  </div>



                </div>



                <div class="right-div2">

                  <div class="my-box" style="padding-top: 5px; padding-bottom: 5px;"> <!-- 调整整体间距 -->
<!--                    <h2 style="margin-top: 0px; margin-bottom: 0px;">示例船舶{{selectedBoat.name}}:</h2>-->
                    <div class="image-slider">
                      <el-carousel :interval="3000" trigger="hover" arrow="always">
                        <el-carousel-item v-for="image in this.images" :key="image">
                          <img :src="image" alt="船舶图片" style="width: 100%; height: 100%; object-fit: cover;">
                        </el-carousel-item>
                      </el-carousel>
                    </div>
                  </div>

                  <div class="my-box" style=";margin-top: 0px" > <!-- 调整整体间距 -->
<!--                    <h2 style="margin-bottom: 20px;">船舶信息</h2>-->
                    <!-- 添加左右布局的容器 -->
                      <!-- 右侧区域 -->
                      <div style="flex: 68.6%;  border: 1px solid #ccc; border-radius: 5px;padding:15px 0 0 0;background-color:#ecf5ff ">
                        <!-- 右侧内容 -->
                        <div class="flex-container">
                          <div class="leftHalf">
                            <p class="item-row">
                              <span class="item-label">IMO:</span>
                              <span class="item-value">{{ selectedItem.imo }}</span>
                            </p>
                            <p class="item-row">
                              <span class="item-label">船旗:</span>
                              <span class="item-value">{{ translateFlag(selectedItem.flag) }}</span>
                            </p>
                            <p class="item-row2">
                              <span class="item-label">长/宽:</span>
                              <span class="item-value" v-if="isLog">{{ selectedItem.length }}m/{{selectedItem.width}}m</span>
                              <span class="item-value" v-if="!isLog">🔒</span>
                            </p>
                            <p class="item-row2">
                              <span class="item-label">航速:</span>
                              <span class="item-value" v-if="isLog">{{ boatNewInfo.sog }}</span>
                              <span class="item-value" v-if="!isLog">🔒</span>
                            </p>
                            <p class="item-row2">
                              <span class="item-label">预计到达时间:</span>
                              <span class="item-value" v-if="isLog">{{ (selectedItem.eta / 3600).toFixed(2) }}h</span>
                              <span class="item-value" v-if="!isLog">🔒</span>

                            </p>
                            <p class="item-row2">
                              <span class="item-label">目的港口:</span>
                              <span class="item-value" v-if="isLog">{{ boatNewInfo.dest }}</span>
                              <span class="item-value" v-if="!isLog">🔒</span>
                            </p>
                            <p class="item-row2">
                              <span class="item-label">经度:</span>
                              <span class="item-value" v-if="isLog">{{ boatNewInfo.x }}°</span>
                              <span class="item-value" v-if="!isLog">🔒</span>
                            </p>
                            <p class="item-row2">
                              <span class="item-label">总吨位: NULL</span>
                              <span class="item-value" v-if="isLog"></span>
                              <span class="item-value" v-if="!isLog">🔒</span>
                            </p>
                            <p class="item-row2">
                              <span class="item-label">载重吨: NULL</span>
                              <span class="item-value" v-if="isLog"></span>
                              <span class="item-value" v-if="!isLog">🔒</span>
                            </p>
                          </div>
                          <div class="rightHalf">
                            <p class="item-row2">
                              <span class="item-label">MMSI:</span>
                              <span class="item-value">{{ selectedItem.mmsi }}</span>
                            </p>
                            <p class="item-row2">
                              <span class="item-label">类型:</span>
                              <span class="item-value" v-if="isLog">{{ translateType(selectedItem.type) }}</span>
                              <span class="item-value" v-if="!isLog">🔒</span>
                            </p>
                            <p class="item-row2">
                              <span class="item-label">吃水深度:</span>
                              <span class="item-value" v-if="isLog">{{boatNewInfo.draught }}</span>
                              <span class="item-value" v-if="!isLog">🔒</span>
                            </p>
                            <p class="item-row2">
                              <span class="item-label">呼号:</span>
                              <span class="item-value" v-if="isLog">{{ selectedItem.callsign }}</span>
                              <span class="item-value" v-if="!isLog">🔒</span>
                            </p>
                            <p class="item-row2">
                              <span class="item-label">母港:</span>
                              <span class="item-value" v-if="isLog">{{ selectedItem.homeport }}</span>
                              <span class="item-value" v-if="!isLog">🔒</span>
                            </p>
                            <p class="item-row2">
                              <span class="item-label">状态:</span>
                              <span class="item-value" v-if="isLog">{{ translateStatus(selectedItem.nav_status) }}</span>
                              <span class="item-value" v-if="!isLog">🔒</span>
                            </p>
                            <p class="item-row2">
                              <span class="item-label">纬度:</span>
                              <span class="item-value" v-if="isLog">{{ boatNewInfo.y }}°</span>
                              <span class="item-value" v-if="!isLog">🔒</span>
                            </p>
                            <p class="item-row2">
                              <span class="item-label">净吨位: NULL</span>
                              <span class="item-value" v-if="isLog"></span>
                              <span class="item-value" v-if="!isLog">🔒</span>
                            </p>
                            <p class="item-row2">
                              <span class="item-label">船籍港: NULL</span>
                              <span class="item-value" v-if="isLog"></span>
                              <span class="item-value" v-if="!isLog">🔒</span>
                            </p>
                          </div>
                        </div>
                      </div>


                  </div>
                </div>



              </div>


            </div>




          </div>
        </div>
        <div v-if="this.num === 3">
          <p style="color: #888888;margin-left: 20px">当前位置：个人中心--我的区域</p>
        </div>
        <div v-if="this.num === 4">
          <p style="color: #888888;margin-left: 20px">当前位置：个人中心--消息</p>
        </div>
      </div>
    </div>





  </div>

</template>

<script>
import topDiv from "@/components/topDiv";
import L from "leaflet";
import {getAISnowByMMSI, getBoatURL, getLocationBoat} from "@/utils/api";


export default {


  name: "myinfo",
  mounted(){
    // this.getUserName();
    // this.initMap();
  },
  computed:{
    isLog() {
      return this.$store.state.isLog;
    },
  },
  created() {
    // 检查路由查询参数并更新num
    if (this.$route.query.num) {
      console.log("change num");
      this.num = parseInt(this.$route.query.num, 10); // 确保转换为数字
      this.changeNum(this.num);
    }
  },
  data(){
    return{
      selectedFleetBoat:"",
      allInfo:{
        name:null,
        telephone:null,
        email:null,
        companyName:null,
        companyEmail:null,
        companyPhone:null,
        companyNet:null,
        companyYoubian:null,
        companyCountry:null,
        companyProvince:null,
        companyCity:null,
        companyPosition:null,
      },
      shipList: {
        fleet1: {
          name: "船队1",
          aisChange:false,
          inOut:false,
          area:false,
          weather:false,
          ships: [
            { imo: "I1234567", mmsi: "M1234567", name: "探索一号" },
            { imo: "I1234568", mmsi: "M1234568", name: "探索二号" },
            { imo: "I1234569", mmsi: "M1234569", name: "探索三号" }
          ]
        },
        fleet2: {
          name: "船队2",
          aisChange:false,
          inOut:false,
          area:true,
          weather:true,
          ships: [
            { imo: "I2234567", mmsi: "M2234567", name: "勇者一号" },
            { imo: "I2234568", mmsi: "M2234568", name: "勇者二号" },
            { imo: "I2234569", mmsi: "M2234569", name: "勇者三号" }
          ]
        },
        fleet3: {
          name: "船队3",
          aisChange:true,
          inOut:false,
          area:false,
          weather:false,
          ships: [
            { imo: "I3234567", mmsi: "M3234567", name: "一号" },
            { imo: "I3234568", mmsi: "M3234568", name: "二号" },
            { imo: "I3234569", mmsi: "M3234569", name: "三号" }
          ]
        }
      },

      currentSelectFleet: {
          name: "尚未选择船队",
          aisChange:false,
          inOut:false,
          area:false,
          weather:false,
          ships: [
            { imo: "I3234567", mmsi: "M43234567", name: "一号" },
            { imo: "I3234568", mmsi: "432855000", name: "二号" },
            { imo: "I3234568", mmsi: "M43234568", name: "三号" },
            { imo: "I3234568", mmsi: "M43234568", name: "四号" },
            { imo: "I3234569", mmsi: "M43234569", name: "五号" },
            { imo: "I3234569", mmsi: "M43234569", name: "六号" },
            { imo: "I3234569", mmsi: "M43234569", name: "七号" },
            { imo: "I3234569", mmsi: "M43234569", name: "八号" },
          ]
        },
       selectedBoat:{

      },
      images: [
        require("@/assets/img/boat1.jpg"),
        require("@/assets/img/boat2.jpg"),
        require("@/assets/img/boat3.jpg"),
        require("@/assets/img/boat4.jpg"),
        // 在这里添加更多图片路径
      ],
      searchResults: {

      },
      searchQuery: "",
      tempQuery:"",
      selectedBoatInfo:"",
      selectedItem: {

      }, // 存储被选中的船舶或港口信息
      editDialogVisible: false, // 控制对话框的显示
      currentEditingFleet: null, // 当前正在编辑的船队的键
      tempFleetName: '', // 临时存储修改中的船队名称
      num:1,
      boatNewInfo: { },
      page:1,
      userName:null,
      password:null,
      changeInfo:false,
      circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      searchContent:"",
      selectedShip: null,
      currentFleetKey:null,
      addNewFleet:false,
      newFleetName:"",
    }

  },

  methods:{
    async searchBoat() {
      console.log(this.searchQuery);
      if (this.searchQuery.trim() === '') {
        alert("请输入搜索内容");
        // ...其他逻辑...
      } else {
        try {
          // 等待异步操作完成并获取结果
          const response = await getLocationBoat({ keyword: this.searchQuery });
          this.tempQuery = this.searchQuery;
          this.searchResults = response.data.resultData;

        } catch (error) {
          console.error("搜索请求出错: ", error);

        }
      }
    },
    async getBoatImgURL2(){
      if(this.isLog){
        try {
          const response = await getBoatURL({boatKeyWordList:[this.selectedBoatInfo.shipname],pageSize:10,pageNum:1});
          this.images = response.data.resultData.records;
          console.log("搜索成功！");
        } catch (error) {
          console.error("搜索请求出错: ", error);

        }
      }else{
        this.images = [
          require("@/assets/img/loginWarning.png"),
        ]
      }

    },
    translateType(type) {
      const typeMap = {
        'Offshore Support Vessel': '海上支援船',
        // 添加其他类型的映射
      };
      return typeMap[type] || type;  // 如果找不到映射，就返回原始类型
    },
    translateStatus(status) {
      const statusMap = {
        'active': '在航',
        // 添加其他类型的映射
      };
      return statusMap[status] || '未知';  // 如果找不到映射，就返回原始类型
    },
    translateFlag(flag) {
      const flagMap = {
        'Australia': '澳大利亚',
        // 添加其他类型的映射
      };
      return flagMap[flag] || flag;  // 如果找不到映射，就返回原始类型
    },
    selectBoat(ship) {

      this.selectedBoat = ship; // 更新selectedBoat为当前的船

      this.getNewBoatInfo(); // 调用getNewBoatInfo方法
    },
    async getNewBoatInfo(){
      try {
        const response = await getAISnowByMMSI({mmsi:this.selectedBoat.mmsi});
        this.selectedBoatInfo = response.data.resultData;
        await this.getBoatImgURL2();
      } catch (error) {
        console.error("搜索请求出错: ", error);

      }
    },
    async getNewBoatInfo2(){
      try {
        console.log(this.selectedItem.mmsi);
        const response = await getAISnowByMMSI({mmsi:this.selectedItem.mmsi});
        this.boatNewInfo = response.data.resultData;
        this.focusOnLocation(this.boatNewInfo.y,this.boatNewInfo.x);
      } catch (error) {
        console.error("搜索请求出错: ", error);

      }
    },
    changeNum(number){
      this.num = number;
      this.userName = localStorage.getItem('username');
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    changeMyInfo(boolVal){
      this.changeInfo = boolVal;
    },
    deleteFleet(fleetKey) {
      this.$delete(this.shipList, fleetKey);
    },
    addFleet(fleetName) {

      const newFleet = {
        name: fleetName,
        aisChange: false,
        inOut: false,
        area: false,
        weather: false,
        ships: []
      };
      this.$set(this.shipList, `fleet${Object.keys(this.shipList).length + 1}`, newFleet);
      this.addNewFleet = false;
      this.$message({
        message: '创建船队成功',
        type: 'success'
      });
    },
    removeShip(fleetKey, shipIndex) {
      this.shipList[fleetKey].ships.splice(shipIndex, 1);
    },
    renameFleet(fleetKey) {
      const newName = prompt("请输入新的船队名称:");
      if (newName !== null && newName.trim() !== "") {
        this.shipList[fleetKey].name = newName;
      }
    },
    showEditDialog(fleetKey) {
      this.currentEditingFleet = fleetKey;
      this.tempFleetName = this.shipList[fleetKey].name;
      this.editDialogVisible = true;
    },
    showNewFleetDialog() {
      this.addNewFleet = true;
    },
    updateFleetName() {
      if (this.currentEditingFleet) {
        this.shipList[this.currentEditingFleet].name = this.tempFleetName;
        this.editDialogVisible = false;
      }
    },
    selectFleet(index) {
      console.log(index);
      this.currentSelectFleet = this.shipList[index];
      this.currentFleetKey = index;
      this.page = 1;
    },
    addNewBoat(fleetKey){
      this.page = 2;
      this.currentSelectFleet = this.shipList[fleetKey];
      this.currentFleetKey = fleetKey;

    },
    addBoat(ship){
      const shipInfo1 = {
        imo: ship.imo,
        mmsi: ship.mmsi,
        name: ship.name
      };

      if (this.shipList[this.currentFleetKey] && this.shipList[this.currentFleetKey].ships) {
        this.shipList[this.currentFleetKey].ships.push(shipInfo1);
        this.currentSelectFleet = this.shipList[this.currentFleetKey];

      } else {
        console.log("Fleet does not exist or has no ships array");
      }
      this.$message({
        message: '船舶成功加入船队',
        type: 'success'
      });
    },
    clearContent(){
      this.searchQuery="";
    },
    updateSearchQuery(item) {
      this.searchQuery = item.name;
      this.selectedItem = item;
      this.selectedShip = item;
      this.isShip = true;
      this.getNewBoatInfo2();

    },

  },


  components: {
    topDiv,
  },

}
</script>

<style scoped>
.logo {
  /*position: absolute;*/
  /*left: 100px;*/
  /*top: -100%;*/
  /*transform: translate(0%, -50%);*/
  max-width: 100%;
  max-height: 100%;
  margin-top: 20px;
  margin-left: 10px;
}

.item-row {
  display: flex;
  justify-content: space-between; /* 使两个子元素分布在两侧 */
  align-items: center;
  font-size: 15px;
  color: #888888;
  margin-bottom: 24px;
}
.item-row2 {
  display: flex;
  justify-content: space-between; /* 使两个子元素分布在两侧 */
  align-items: center;
  font-size: 15px;
  color: #888888;
  margin-bottom: 12px;
}
.item-label {
  text-align: left; /* 左对齐文本 */
  margin-left: 8px;
}

.item-value {
  text-align:center; /* 右对齐文本 */
  margin-left: auto; /* 推送到右侧 */
  flex: 1; /* 允许这个元素增长，填充剩余空间 */
}
.leftHalf, .rightHalf {
  width: 50%; /* 每个半区占用50%的宽度 */
  /* 如果你希望内容有更多的空间，可以略微减少宽度，例如 48% 然后给每个部分增加内边距 */
}

.boatInfoFrame {
  margin-bottom: 10px; /* 增加间距 */
  /*background-color: #d2e1ff;*/
  background-color: #ecf5ff;
  padding: 3px; /* 减小内边距以减小高度，同时保持内容的可读性 */
  border-radius: 10px; /* 添加圆角效果 */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 999;
}

.boatInfoFrame:hover {
  background-color: #f2f5e0; /* 悬停时的背景色 */
  cursor: pointer;
}

.boatInfoFrame p {
  font-size: 0.8em; /* 维持原字体大小或根据实际需求调整 */
  color: #65788d;
  margin-bottom: 2px;
}

.flex-container {
  display: flex;
  align-items: center;
}
.flex-container {
  display: flex;
  /*justify-content: space-between;*/
}

.container {
  position: relative;
  width: 100%;
  height: 93.4vh; /* 设置容器的高度为视窗的百分之百 */
}

.left-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 10%;
  height: 90%;
  background-color: #ffffff;
  margin-top: 30px;
}

.right-div {
  position: absolute;
  top: 0;
  right: 0;
  width: 90%;
  height: 100%;
  background-color: #f2f2f2;
}

.shipListClass {
  margin-left: 30px;
  margin-right: 20px;
}
.hover-li {
  background-color: #ecf5ff;
  padding: 5px;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
}

.hover-li:hover {
  background-color: #f8f8d7; /* 浅绿色 */
}


/* 添加到 <style> 中 */
.hover-effect:hover {
  background-color: #e0e0e0; /* 鼠标悬停时的背景色 */
}

.container2 {
  display: flex; /* 启用 Flexbox */
  width: 100%; /* 设置容器宽度 */
}

.left-side, .right-side {
  flex: 1; /* 使每个子容器平分父容器的宽度 */
  height: 710px; /* 示例高度，根据需要调整 */
}

.left-side {
  /*background-color: ; !* 左侧背景颜色 *!*/
}

.right-side {
  /*background-color: #f4f4f9; !* 右侧背景颜色 *!*/
}
.my-box {
  margin: 10px 25px;
  background: #f4f4f9;
  padding: 5px 10px 10px 30px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  border: 1px solid #ccc; /* Add a border */
  border-radius: 5px;

}
.extra-spacing {
  margin-right: 30px; /* 可根据需要调整右侧的间距 */
}
.fleet-name:hover {
  color: orange;
}

.search-box {
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 30px;
  position: relative; /* 设置相对定位 */
}

.search-input {
  /*flex: 1; !* 充满剩余空间 *!*/
  padding: 15px;
  width: 100%;
  border: none;
  border-radius: 10px 10px 10px 10px; /* 左侧圆角 */
  background-color: rgba(238, 238, 238, 0.89);
  color: #000000;
  outline: none;
  padding-left: 30px; /* 为图标腾出空间 */
}
.icon-container {
  margin-right: 10px;
  position: absolute; /* 设置绝对定位 */
  top: 50%; /* 向下移动50%的高度 */
  left: 10px; /* 从左边框向右10px */
  transform: translateY(-50%); /* 向上移动自身高度的50% */
  z-index: 10; /* 确保图标在输入框之上 */
}

.icon-container i {
  cursor: pointer;
  font-size: 16px;
  color: #888888;
}
.icon-container:hover i {
  color: #65788d; /* 悬停时的颜色 */
}

.left-div2 {
  flex-grow: 0.2; /* 使左容器占38.8% */
  /*background-color: #184203;*/
  /*height: 710px; !* 示例高度，根据需要调整 *!*/
}

.right-div2 {
  flex-grow: 0.8; /* 使右容器占61.8% */
  /*background-color: #65788d;*/
  /*height: 710px; !* 示例高度，根据需要调整 *!*/
}

.sidebar {
  width: 85%;
  min-width: 300px;
  height: 620px;
  background-color: rgba(242,242,247,0.2);
  padding: 10px;
  box-shadow: 0px 0 5px 3px rgba(0, 0, 0, 0.2);
  margin-right: 3px;
  margin-left: 30px;
  border-radius: 5px;
  margin-top: 10px;

}

.vf-div{
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 95%;
}



/* 创建新的滚动条样式 */
.vf-div::-webkit-scrollbar {
  width: 8px; /* 设置滚动条宽度 */
  height: 60px;
}

 /* 滚动条上按钮的样式 */
.vf-div::-webkit-scrollbar-button {
  display: none; /* 不显示按钮 */
}

 /* 滚动条轨道的样式 */
.vf-div::-webkit-scrollbar-track {
  background-color: #f5f5f5; /* 设置背景色 */
}

 /* 滚动条滑块的样式 */
.vf-div::-webkit-scrollbar-thumb {
  border-radius: 4px; /* 设置边角圆形化 */
  background-color: #ccc; /* 设置滑块颜色 */
}

 /* 当滑块被点击时的效果 */
.vf-div::-webkit-scrollbar-thumb:hover {
  background-color: #999; /* 设置滑块鼠标悬停时的颜色 */
}
.search-result {
  /* 调整每个结果的高度，例如使其基于内容自适应 */
  margin-bottom: 0px;

  background-color: #e1ffff;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 999;
  /* 可以添加 max-height 或具体的 height 根据需求调整 */
}

.search-result:hover {
  background-color: #f8fde1;
  /* 悬停时的背景色 */
  cursor: pointer;
}

.search-result p {
  /* 减小段落文本的字体大小 */
  font-size: 0.8em; /* 或者使用具体的像素值，例如 12px */
  color: #65788d;
}

/deep/ .search-result {
  background-color: rgba(223,232,250,0.5) !important;
  overflow: visible;
}
.search-result:hover, .selected {
  background-color: rgba(223,232,255,1) !important;

  /*font-size: 17px;*/
}

.selectedFleetBoatColor{
  background-color: #f8f8d7;
}

</style>
